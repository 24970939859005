<template>
  <div id="cgu_periodiques">
    <CguPeriodiquesDe v-if="country === 'de'" />
    <CguPeriodiquesFr v-else />
  </div>
</template>

<script>
import CguPeriodiquesFr from "@/components/legal/periodiques/CguPeriodiquesFr.vue";
import CguPeriodiquesDe from "@/components/legal/periodiques/CguPeriodiquesDe.vue";

import { mapGetters } from "vuex";

/**
 * Affiche les CGU de Periodiques dans la bonne langue.
 */
export default {
  name: "CguPeriodiques",
  components: {
    CguPeriodiquesFr,
    CguPeriodiquesDe,
  },
  computed: {
    ...mapGetters(["country"]),
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_legal.scss";
</style>
